import { Extension } from "@tiptap/core"

export const LineHeight = Extension.create({
    name: "lineHeight",

    addOptions() {
        return {
            types: ["textStyle"],
            sizes: new Array(13).fill(7).map((number, index) => `${(number + index + 1) / 10}`)
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        parseHTML: element => element.style.lineHeight,
                        renderHTML: attributes => {
                            if (!attributes.lineHeight) {
                                return {}
                            }

                            return { style: `line-height: ${attributes.lineHeight}` }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setLineHeight:
                lineHeight =>
                ({ chain }) => {
                    return chain().setMark("textStyle", { lineHeight }).run()
                }
        }
    }
})
