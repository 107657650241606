<template>
    <div
        v-if="isSeparate() || displayAll || isAllowClear() || isAllowFinish()"
        :class="{ 'practice-sticky-container': !isSeparate() && !isOneQuestion() }"
        class="mt-20"
    >
        <div v-if="isSeparate() || displayAll" class="practice-actions-container">
            <button v-if="isAllowClear() && !isSeparate()" @click="$emit('clear')" class="practice-clear-action">
                {{ t("create-course.clear_form") }}
            </button>

            <button v-if="isAllowPrevious()" @click="$emit('prev')" class="practice-next-action">
                <span v-if="$screenWidth > 640">←</span> {{ t("expert.back") }}
            </button>
            <div v-else-if="!isOneQuestion()">
                <default-title class="whitespace-nowrap" v-if="!isSeparate()" large color="#fff">
                    {{ quiz.questions.map(isCompleted).filter(invalid => !invalid).length }} /
                    {{ quiz.questions.length }}
                </default-title>
            </div>

            <div class="practice-actions-container">
                <button class="practice-check-action" @click="$emit('check')" v-if="isDisplayQuestionResult()">
                    {{ t("create-course.check") }}
                </button>

                <button @click="$emit('check')" v-else-if="isDisplayChangeButton()" class="practice-next-action">
                    {{ t("quiz.change") }}
                </button>

                <button
                    @click="$emit(isNotLast() ? 'next' : 'finish')"
                    :class="{
                        'practice-next-action_disabled': (disabled && isSeparate()) || fileLoading,
                        'pointer-events-none': loaders && loaders.finish
                    }"
                    class="practice-next-action"
                >
                    <SpinLoader color="#fff" :width="28" v-if="loaders && loaders.finish" />
                    <template v-else-if="!isSeparate()"> {{ t("create-course.send") }} </template>
                    <template v-else-if="isOneQuestion()"> {{ t("common.answer") }} </template>
                    <template v-else-if="isNotLast()"> {{ t("course.practice.next") }} <span>→</span> </template>
                    <template v-else> {{ t("course.practice.finish") }} </template>
                </button>
            </div>
        </div>

        <div
            v-if="(isAllowClear() || isAllowFinish()) && isSeparate()"
            class="practice-actions-container practice-actions-container_center mt-20"
        >
            <button v-if="isAllowClear()" @click="$emit('clear')" class="practice-clear-action">
                {{ t("create-course.clear_form") }}
            </button>
            <button v-if="isAllowFinish()" @click="$emit('finish')" class="practice-finish-action">
                {{ t("course.practice.finish") }}
            </button>
        </div>
    </div>
</template>

<script>
import { QUESTION_DISPLAY_TYPES, REANSWER_TYPES, RETURN_TO_PREVIOUS } from "@components/Practice/common/_types"
import SpinLoader from "@components/Loaders/SpinLoader.vue"
import { isCompletedQuestion, isInvalidQuestion } from "@components/Practice/PracticeAttempt/practice-attempt-mixin"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { EventBus } from "~events"

export default {
    components: { DefaultTitle, SpinLoader },
    props: {
        displayAll: {
            type: Boolean,
            default: false
        },
        question: {
            type: Object,
            default: null
        },
        interactive: {
            type: Boolean,
            default: true
        },
        loaders: {
            type: Object,
            default: null
        },
        quiz: {
            type: Object,
            default: null
        }
    },
    name: "PracticeActionsContainer",
    data() {
        return {
            QUESTION_DISPLAY_TYPES,
            REANSWER_TYPES,
            disabled: false,
            fileLoading: false
        }
    },
    created() {
        EventBus.$on("fileLoading", val => {
            this.fileLoading = val
        })

        this.disabled = this.isDisabled()
    },
    methods: {
        isNotLast() {
            return this.quiz.questions.indexOf(this.question) !== this.quiz.questions.length - 1
        },
        isPreLast() {
            return this.quiz.questions.indexOf(this.question) === this.quiz.questions.length - 2
        },
        isNotFirst() {
            return this.quiz.questions.indexOf(this.question) !== 0
        },
        isAllowFinish() {
            if (this.isSeparate() && this.isNotLast()) {
                return this.quiz.finish_type_id === 1
            }

            return false
        },
        isAllowClear() {
            return !this.isSeparate() && this.quiz.is_allow_clear && this.displayAll
        },
        isAllowPrevious() {
            return (
                this.isSeparate() && this.isNotFirst() && this.quiz.return_to_previous_type !== RETURN_TO_PREVIOUS.NONE
            )
        },
        isDisplayQuestionResult() {
            return this.isSeparate() && this.quiz?.type_show_question_result_attempt?.id !== 1 && this.interactive
        },
        isSeparate() {
            return this.quiz.questions_display_type_id === QUESTION_DISPLAY_TYPES.SEPARATELY
        },
        isOneQuestion() {
            return this.quiz.questions.length === 1
        },
        isInvalid(question) {
            return isInvalidQuestion(question)
        },
        isDisplayChangeButton() {
            return this.quiz.reanswer_type === REANSWER_TYPES.YES && !this.interactive
        },
        isCompleted(question) {
            return isCompletedQuestion(question)
        },
        isDisabled() {
            if (!this.interactive) {
                return false
            }
            if (this.isSeparate()) {
                return this.isInvalid(this.question)
            } else {
                return !!this.quiz.questions.map(this.isInvalid).find(invalid => !!invalid)
            }
        }
    },
    watch: {
        quiz: {
            deep: true,
            handler() {
                if (!this.isSeparate()) {
                    this.disabled = this.isDisabled()
                }
            }
        },
        question: {
            deep: true,
            handler() {
                if (this.isSeparate()) {
                    this.disabled = this.isDisabled()
                }
            }
        }
    }
}
</script>

<style scoped lang="sass">
.practice-sticky-container
    position: static
    border-radius: 4px
    z-index: 99
.practice-actions-container
    display: flex
    justify-content: space-between
    align-items: center
    grid-gap: 10px
    @media (max-width: 640px)
        flex: 1
    &_center
        justify-content: center
    &:not(&_center)
        @media (max-width: 640px)
            flex-direction: column
            align-items: stretch
.practice-clear-action
    background: #FFF
    color: #000
    text-underline-offset: 4px
    &:hover
        text-decoration: underline
.practice-finish-action
    border: 1px solid #DD4141
    box-shadow: 0px 4px 10px 0px #809EBF26
    color: #DD4141
    &:hover
        color: #FFF
        background: #DD4141
.practice-next-action
    border: 1px solid #3965FF
    box-shadow: 0px 4px 10px 0px #809EBF26
    color: #3965FF
    background-color: #fff
    &_disabled
        opacity: .4
        pointer-events: none
    &::v-deep
        .spin-loader
            circle
                stroke: #3965FF !important
    &:hover
        color: #FFF
        background: #2E54DC
        &::v-deep
            .spin-loader
                circle
                    stroke: #FFF !important
.practice-check-action
    background-color: #dad9d9
    border: #dad9d9
    color: #323030
    &:hover
        filter: brightness(85%)

.practice-next-action, .practice-clear-action, .practice-finish-action, .practice-check-action
    min-width: 135px
    height: 45px
    border-radius: 4px
    display: flex
    user-select: none
    align-items: center
    justify-content: center
    font-size: 14px
    font-weight: 400
    line-height: 20px
    padding: 0 10px
    transition: .2s
    font-family: 'Inter', sans-serif
    @media (max-width: 640px)
        height: 40px
        min-height: 40px
        font-size: 14px
        min-width: 0
        flex: 1
    span
        font-family: 'Lato', sans-serif
        margin-left: 5px
        margin-right: 5px
</style>
